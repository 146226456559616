<template>
  <b-container fluid>
      <div class="card card-primary">
        <div class="card-header">
          <h3 class="card-title">
            Rechnungskorrektur:
            <template v-if="storno.document != null">{{storno.document.number}}</template>
            <template v-else>- none -</template>
          </h3>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-md-4" style="display: flex; flex-direction: column;">
              <div class="card card-outline card-primary" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                <div class="card-header">
                  <h3 class="card-title">Rechnungsanschrift</h3>
                </div>

                <div class="card-body">
                  <template v-if="storno.order.billing.company != null">
                    {{ storno.order.billing.company }}<br>
                    <template v-if="storno.order.billing.department != null">
                      {{ storno.order.billing.department }}<br>
                    </template>
                  </template>
                  {{ storno.order.billing.first_name }} {{ storno.order.billing.last_name }}<br>
                  {{ storno.order.billing.street }} {{ storno.order.billing.house_number }}<br>
                  {{ storno.order.billing.zipcode }} {{ storno.order.billing.city }}<br>
                </div>
              </div>
            </div>

            <div class="col-md-4" style="display: flex; flex-direction: column;">
              <div class="card card-outline card-primary" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                <div class="card-header">
                  <h3 class="card-title">Lieferanschrift</h3>
                </div>

                <div class="card-body">
                  <template v-if="storno.order.shipping.company != null">
                    {{ storno.order.shipping.company }}<br>
                    <template v-if="storno.order.shipping.department != null">
                      {{ storno.order.shipping.department }}<br>
                    </template>
                  </template>
                  {{ storno.order.shipping.first_name }} {{ storno.order.shipping.last_name }}<br>
                  {{ storno.order.shipping.street }} {{ storno.order.shipping.house_number }}<br>
                  {{ storno.order.shipping.zipcode }} {{ storno.order.shipping.city }}<br>
                </div>
              </div>
            </div>

            <div class="col-md-4" style="display: flex; flex-direction: column;">
              <div class="card card-outline card-primary" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                <div class="card-header">
                  <h3 class="card-title">Status</h3>
                </div>

                <div class="card-body">
                  Status: {{ storno.status.description }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card card-primary card-outline">
                <div class="card-header">
                  <h3 class="card-title">Positionen</h3>
                </div>

                <div class="card-body">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Anzahl</th>
                        <th>Preis</th>
                        <th>Steuer</th>
                        <th>Gesamt</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="item in storno.items" :key="item.id">
                        <td>{{ item.name }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.price | toCurrency }}</td>
                        <td>{{ item.tax_id }}</td>
                        <td>{{ item.price * item.quantity | toCurrency }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  </b-container>
</template>

<script>
export default {
  name: "StornoDetails",
  title: "Rechnungskorrektur",

  data(){
    return {
      storno: {
        order:{
          billing: {},
          shipping: {},
        },
      }
    }
  },

  methods: {
    getStorno(){
      this.$Progress.start();
      this.axios
          .get("/stornos/" + this.$route.params.id)
          .then((response) => {
              this.storno = response.data.data;
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
        }
    },

  mounted() {
    this.getStorno();
  }
}
</script>

<style>

</style>